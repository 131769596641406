const COLORS = {
        red: '#F92607',
        orange: '#EA8102',
        blue: '#029CE6',
        black: '#040205',
        grey: '#D0CDD4',
        purple: '#9B31C5',
        green: '#02A710',
        white: '#FFFFFF',
};


export default COLORS;
